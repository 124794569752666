<template>
  <c-box
    width="100%"
  >
    <c-box
      margin="auto"
      width="100%"
      overflow="hidden"
    >
      <c-box
        :height="iframeHeight+'px'"
      >
        <iframe
          :src="getIframeLink"
          frameborder="0"
          style="display:block; width:100%; height: 100%;"
        />
      </c-box>
    </c-box>
    <c-box
      id="contact"
      margin="auto"
      width="100%"
      overflow="hidden"
    >
      <c-box
        :height="iframeHeightContact+'px'"
      >
        <iframe
          :src="getIframeLinkContact"
          frameborder="0"
          style="display:block; width:100%; height: 100%;"
        />
      </c-box>
    </c-box>
    <BaseModal
      :is-open="!!isOpenModalFormDfe"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="120px"
            object-fit="cover"
            :src="require('@/assets/images/sent-success.svg')"
            alt="image question"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          justify-content="center"
          padding="30px 16px 20px 16px"
          text-align="center"
        >
          <BaseText
            size-mobile="20px"
            size-desktop="24px"
            color="primary.400"
            margin-bottom="8px"
          >
            {{ modalDfe.title }}
          </BaseText>
          <BaseText
            size-mobile="14px"
            size-desktop="16px"
            color="primary.400"
          >
            {{ modalDfe.description }}
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            @click="isOpenModalFormDfe = false"
          >
            Oke
          </BaseButton>
        </c-flex>
      </template>
    </BaseModal>
  </c-box>
</template>

<script>
import { ENV } from '@/constants/config'
import BaseModal from '@/components/elements/base-modal.vue'
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'

export default {
  name: 'LayananEnterprisePage',
  components: {
    BaseModal,
    BaseText,
    BaseButton,
  },
  data() {
    return {
      isOpenModalFormDfe: false,
      iframeHeight: 1000,
      iframeHeightContact: 1000,
      modalDfe: {
        title: '',
        description: '',
      },
    }
  },
  computed: {
    getIframeLink() {
      return `${ENV.URL_DIETELA_LANDING_PAGE}/dietela-for-enterprise`
    },
    getIframeLinkContact() {
      return `${ENV.URL_DIETELA_LANDING_PAGE}/dietela-for-enterprise-contact`
    },
  },
  mounted() {
    window.addEventListener('message', event => {
      switch (event.data?.id) {
        case 'jump-to-contact':
          this.$router.replace({ name: 'client.enterpriseService', hash: '#contact' })
          break
        case 'form-dfe':
          this.modalDfe.title = event.data?.title
          this.modalDfe.description = event.data?.description
          this.isOpenModalFormDfe = true
          break
        case 'resize-iframe':
          this.iframeHeight = event.data?.height
          break
        case 'resize-iframe-contact':
          this.iframeHeightContact = event.data?.heightContact
          break
        default:
          break
      }
    }, false)
  },
}
</script>
