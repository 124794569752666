var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-box', {
    attrs: {
      "margin": "auto",
      "width": "100%",
      "overflow": "hidden"
    }
  }, [_c('c-box', {
    attrs: {
      "height": _vm.iframeHeight + 'px'
    }
  }, [_c('iframe', {
    staticStyle: {
      "display": "block",
      "width": "100%",
      "height": "100%"
    },
    attrs: {
      "src": _vm.getIframeLink,
      "frameborder": "0"
    }
  })])], 1), _c('c-box', {
    attrs: {
      "id": "contact",
      "margin": "auto",
      "width": "100%",
      "overflow": "hidden"
    }
  }, [_c('c-box', {
    attrs: {
      "height": _vm.iframeHeightContact + 'px'
    }
  }, [_c('iframe', {
    staticStyle: {
      "display": "block",
      "width": "100%",
      "height": "100%"
    },
    attrs: {
      "src": _vm.getIframeLinkContact,
      "frameborder": "0"
    }
  })])], 1), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpenModalFormDfe,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-image', {
          attrs: {
            "height": "120px",
            "object-fit": "cover",
            "src": require('@/assets/images/sent-success.svg'),
            "alt": "image question"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "justify-content": "center",
            "padding": "30px 16px 20px 16px",
            "text-align": "center"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "20px",
            "size-desktop": "24px",
            "color": "primary.400",
            "margin-bottom": "8px"
          }
        }, [_vm._v(" " + _vm._s(_vm.modalDfe.title) + " ")]), _c('BaseText', {
          attrs: {
            "size-mobile": "14px",
            "size-desktop": "16px",
            "color": "primary.400"
          }
        }, [_vm._v(" " + _vm._s(_vm.modalDfe.description) + " ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalFormDfe = false;
            }
          }
        }, [_vm._v(" Oke ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }